import { RegisterPricingPageToolTips } from "@components/BuilderComponents/PricingPageToolTips";
import { RegisterBannerButtonLarge } from "@components/BuilderWrapperComponents/BannerButtonLarge";
import { RegisterBannerLinkSmall } from "@components/BuilderWrapperComponents/BannerLinkSmall";
import { RegisterBannerLinksFullWidthWrapper } from "@components/BuilderWrapperComponents/BannerLinksFullwidthWrapper";
import { RegisterCareersSection } from "@components/BuilderWrapperComponents/CareersSectionBC";
import { RegisterContent4columnGridWrapper } from "@components/BuilderWrapperComponents/Content4columnGridWrapper";
import { RegisterContentAccordian } from "@components/BuilderWrapperComponents/ContentAccordian";
import { RegisterContentAnchorlinksBox } from "@components/BuilderWrapperComponents/ContentAnchorlinksBox";
import { RegisterCtaButtonPlayVideo } from "@components/BuilderWrapperComponents/CtaButtonPlayVideo";
import { RegisterDotStatus } from "@components/BuilderWrapperComponents/DotStatusWrapper";
import { RegisterEmailCaptureLeadsWrapper } from "@components/BuilderWrapperComponents/EmailCaptureLeadsWrapper";
import { RegisterEmailCaptureWBackground } from "@components/BuilderWrapperComponents/EmailCaptureWBackground";
import { RegisterEmailOnlyForm } from "@components/BuilderWrapperComponents/EmailOnlyFormBC";
import { RegisterFaqsContainer } from "@components/BuilderWrapperComponents/FaqsAccordionBC";
import { RegisterFaqsList2column } from "@components/BuilderWrapperComponents/FaqsList2column";
import { RegisterFeatureCardsSection } from "@components/BuilderWrapperComponents/FeatureCardsSectionBC";
import { RegisterGlobalBannerSection } from "@components/BuilderWrapperComponents/GlobalBannerSectionBC";
import { RegisterLinkArrowWrapper } from "@components/BuilderWrapperComponents/LinkArrowWrapper";
import { RegisterOfficeTourSection } from "@components/BuilderWrapperComponents/OfficeTourSectionBC";
import { RegisterPrimaryBtn } from "@components/BuilderWrapperComponents/PrimaryBtnBC";
import { RegisterPrimaryBtnWithArrow } from "@components/BuilderWrapperComponents/PrimaryBtnWithArrowBC";
import { RegisterReviews } from "@components/BuilderWrapperComponents/ReviewsBC";
import { RegisterSignUpForm } from "@components/BuilderWrapperComponents/SignUpFormBC";
import { RegisterSocialLinksWrapper } from "@components/BuilderWrapperComponents/SocialLinksWrapper";
import { RegisterTestimonialCardsArrows } from "@components/BuilderWrapperComponents/TestimonialCardsArrows";
import { RegisterTestimonialsSlider } from "@components/BuilderWrapperComponents/TestimonialSliderPContainer";
import { RegisterTooltipIcon } from "@components/BuilderWrapperComponents/TooltipIcon";
import { RegisterVideoEmbed } from "@components/BuilderWrapperComponents/VideoEmbed";
import { RegisterLinkPractitioner } from "../components/BuilderWrapperComponents/LinkPractitioner";
import { RegisterResourceSliderWrapper } from "@components/BuilderWrapperComponents/ResourceSliderWrapper";
import { RegisterContent4columnGridHoverWrapper } from "@components/BuilderWrapperComponents/Content4columnGridHoverWrapper";
import { RegisterSignupFormDrip } from "@components/BuilderWrapperComponents/SignupFormDripWrapper";

export const RegisterComponentPageBlank = () => {
  RegisterLinkPractitioner();
  RegisterOfficeTourSection();
  RegisterFaqsContainer();
  RegisterPrimaryBtnWithArrow();
  RegisterReviews();
  RegisterPrimaryBtn();
  RegisterEmailOnlyForm();
  RegisterSignUpForm();
  RegisterCareersSection();
  RegisterFeatureCardsSection();
  RegisterVideoEmbed();
  RegisterContentAnchorlinksBox();
  RegisterBannerLinkSmall();
  RegisterContentAccordian();
  RegisterFaqsList2column();
  RegisterBannerButtonLarge();
  RegisterDotStatus();
  RegisterTestimonialsSlider();
  RegisterTooltipIcon();
  RegisterTestimonialCardsArrows();
  RegisterCtaButtonPlayVideo();
  RegisterGlobalBannerSection();
  RegisterEmailCaptureLeadsWrapper();
  RegisterSocialLinksWrapper();
  RegisterBannerLinksFullWidthWrapper();
  RegisterContent4columnGridWrapper();
  RegisterLinkArrowWrapper();
  RegisterEmailCaptureWBackground();
  RegisterPricingPageToolTips();
  RegisterResourceSliderWrapper();
  RegisterContent4columnGridHoverWrapper();
  RegisterSignupFormDrip();
};
